@import url('https://fonts.maateen.me/solaiman-lipi/font.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');

.bag-orange{background-color: #fa960a;color: #FFF}
.no-margin{margin-left: 0;margin-right: 0;}
img{max-width: 100%}
body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Roboto",Helvetica,Arial,sans-serif,SolaimanLipi;
    font-size: 16px;
}
.ml-3{margin-left: 15px;}
body a:hover{  text-decoration: none;}
a, h1, h2, h3, h5, h6, p{font-family: "Roboto", SolaimanLipi}
a{color: #fea621}
.no-border-radius{border-radius: 0px}
.c-perple{color: #c11c5a}
.c-yellow{color: #db9e30}
.c-green{color: #57a68f}
.c-orange{color: #e95823}
.c-perple:hover{color: #fea621}
.c-yellow:hover{color: #fea621}
.c-green:hover{color: #fea621}
.c-orange:hover{color: #fea621}

.white-border{border: 1px solid #DDD}
.bg-transparent{background-color: transparent}


/*
Primary Color: #fa960a
Primary Color: #000000
*/

/*Simple animation*/

/*simple animation*/
.navbar.top-nav .navbar-nav .nav-link, .blo-box .blog-overflow-content, .blog-overflow-content p, .single-products .book-block-footer, .single-products img, .footer-social li a, .footer_menus ul li a, .blog-squre .post-info, .blog-squre img, .contact-block .contact_details, .contact-block .contact_block_icon, .contact-block, .at-user__avatar, .at-user, .at-social, .single-books .book-block-footer, .single-books img{-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out;-o-transition: all .3s ease-in-out; transition: all .3s ease-in-out;}

.header_top_section{background-color: #373637;color: #FFF}
.header_top_section p span{color: #f14808}
.header_top_section p {font-size: 14px;padding: 3px 0px}
.header_top_section p a{color: #fff;text-decoration: none;display: block;}


/*This style for menu*/
.navbar.top-nav{border-bottom: 1px solid #DDD;}

.navbar.top-nav .navbar-nav{margin-left: auto}
.navbar.top-nav .navbar-brand span{color: #fa960a}

.navbar.top-nav .navbar-nav .nav-item{
    padding-left: 1px;padding-right: 1px;
}
.navbar.top-nav .navbar-nav .nav-link {
	color: #f14808;
	font-weight: 400;
	padding: 5px 9px;
}
.navbar.top-nav .navbar-nav .nav-link:focus, .navbar.top-nav .navbar-nav .nav-link:hover, .navbar.top-nav .navbar-nav .nav-item .nav-link.active > .nav-link{
    background: #f14808;
    color: #fff;
    border-radius: 20px 0px 20px 0px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}
.navbar.top-nav .navbar-nav .nav-item .nav-link.active{
    background: #f14808;
    color: #fff;
    border-radius: 20px 0px 20px 0px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;}

.navbar-light .navbar-brand {
	color: rgba(0,0,0,.9);
}
.top-nav-brand img{
    max-height: 50px;
}


/*This style for about section*/
.about_content h1 {
	color: #444;
	font-weight: 700;
	font-family: 'Cinzel Decorative', cursive;
	font-size: 30px;
}
.about_content p{color: #3c3c3c; }


/*This style for service section*/

.service_section_title{
    font-family: 'Cinzel Decorative', cursive;
    font-weight: 700;
	text-align: center;
}
.single_service_block {
	padding: 15px;
	/* box-shadow: 0px 0px 35px -10px #DDD; */
	border: 1px solid #E8E8E8;
	border-radius: 20px 0px 20px 0px;
}
.single_service_block span{font-size: 60px;}
.single_service_block h1 {
	font-size: 18px;
}
.single_service_block h1 a{
    font-weight: 700;
    font-family: 'Cinzel Decorative', cursive;
    text-decoration: none;
}
.single_service_block h1 a:hover{
   color: #fea621;
}

.service_image {
	width: 150px;
	height: 150px;
	margin-top: 15px;
	margin-bottom: 15px;
	margin-left: auto;
	margin-right: auto;
}


.hero_bg_big_text {
	color: #FFF;
	background-size: cover;
	background-color: #c11c5a;
}
.hero_box {
	border: 1px solid #fff;
	text-align: center;
	padding: 15px;
	border-radius: 20px 0px 20px 0px;
}
.hero_icon {
	font-size: 40px;
	margin-bottom: 10px;
}
.hero_text {
	font-size: 30px;
}
.hero_text a{color: #FFF;text-decoration: none;}
.hero_text a:hover{color: #FFF;}


/*This style for signle course design*/
.course_section{background:#f7f7f7}
.single_course{background:#FFF}
.single_course h3 {
	color: #f14808;
	font-size: 16px;
	font-weight: normal;
	margin-top: 10px;
}
.course_footer{
	padding: 0px 10px;
	border-bottom: 1px solid #DDD;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.single_course h1 {
	font-weight: normal;
	font-size: 22px;
}
.course_fee{margin: 0;color: #bbb;
    padding: 0px 10px;text-align: right;}
.course_fee span {
	color: #f14808;
	font-weight: bold;
}

.course_footer .course_description {
	margin-bottom: 0px;
	color: #666 !important;
	font-size: 13px;
}

.course_footer .course_description p{
	margin-bottom: 2px;
	color: #666 !important;
	font-size: 13px;
}
.ratting {
	margin: 0;
	padding: 0;
	list-style: none;
    padding: 0px 10px;
}
.ratting li {
	display: inline;
	color: #f14808;
}


/*This Style for team section of team page*/
.single_team {
	background: #f8f9fa;
}
.single_team img{width:100%;
	max-height: 230px;
	min-height: 230px;}
.single_team_footer h1 {
	font-family: 'Cinzel Decorative', cursive;
	font-size: 22px;
	font-weight: 600;
	margin-top: 15px;
}
.single_team_footer h4 {
	color: #f14808;
	font-size: 16px;
}
.single_team_footer {
	padding-bottom: 15px;
	border-bottom: 2px solid #212529;
}


/*This style for footer section*/
.footer_logo_section{
    background: #f14808;
    padding: 10px 0px;
}

.footer_section{background-color: #272727; color: #bbb;}
.footer_widget_title{margin-bottom: 20px;font-size: 18px;}
.footer_contact span{color:#FFF;margin-right: 10px;}
.footer_menu {
	margin: 0;
	padding: 0;
	list-style: none;
}
.footer_menu li {
	border-bottom: 1px dashed #404040;
}
.footer_menu li a{color: #808080;text-decoration: none;}
.footer_menu li a:hover {
	color: #f14808;
}

.list-border{
    list-style: none;
    margin: 0;
    padding: 0;
}
.list-border li {
	border-bottom: 1px dashed #404040;
	color: #808080;
	padding: 5px 0px;
}
.list-border li span {
	float: left;
}
.list-border li div{
	float: right;
}
.footer_bottom_section{background-color: #000 !important;color: #FFF;padding: 10px 0px;}
.footer_bottom_section p{margin: 0;font-size: 14px;}
.footer_bottom_section  a{color:#f14808}



.red_button {
    background-color: #f14808 !important;
    color: #fff;
	border-color: #f14808  !important;
    border-radius: 20px 0px 20px 0px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border: none;
}
.red_button:hover{color: #FFF}
.notfound_title{color: #352c7c;letter-spacing: 5px;}

.map-container{
	overflow:hidden;
	padding-bottom:56.25%;
	position:relative;
	height:0;
  }
  .map-container iframe{
	left:0;
	top:0;
	height:100%;
	width:100%;
	position:absolute;
  }

  .pageLoadingWrapper {
	position: absolute;
	z-index: 9;
	background: #FFF;
}
  .PageLoadingBox img{max-width: 100%;}


  

/*This style for testimonia section title*/
.testimonial_section {
	background: #ededed;
}
.testimonial_section_title {
	color: #f14808;
	font-weight: 700;
	font-family: 'Cinzel Decorative', cursive;
}

.single_testimonial {
	background: #FFF;
	padding: 15px;
	border-radius: 15px 0px 15px 0px;
	width: 100%;
}

.single_testimonial .testimonial_text p{color: #414141;font-size: 14px}
.single_testimonial .testimonial_text p::first-letter{color: #f14808;font-size: 25px}
.single_testimonial .testimonial_viewer .person_name {
	font-size: 16px;
	color: #f14808;
	margin: 0px;
	margin-top: 7px;
}
.single_testimonial .testimonial_viewer .person_address{margin: 0;font-size: 14px;}


.hasLoading{position:relative;min-height: 100px;}
.loadingWrapper{position: absolute;top: 0;left: 0;right: 0;bottom: 0;padding: 15px;}
.loadingBox{height:100%}

.facebookpage iframe{width: 100%;}


  /*Reaponsive styles*/
  /* Small devices (landscape phones, 576px and up)*/
@media (max-width: 576px) {
    .hero_text {
		font-size: 20px;
	}
}

/*  Medium devices (tablets, 768px and up)*/
@media (max-width: 768px) {
	.hero_text {
		font-size: 20px;
	}
}

  
